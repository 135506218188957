import * as React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import createLink from 'utils/createLink';
import { get, isEmpty } from 'lodash';

import RichText from 'components/rich-text/RichText';
import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

import BeerCollage from 'components/beer-collage/BeerCollage';
import Beer from 'components/beer-collage/Beer';

export const query = graphql`
query AllBeersQuery (
  $after: String
) {
  prismic {
    beers: allBeers(
      first: 20
      after: $after
      sortBy: order_timestamp_DESC
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _meta {
            id
            uid
            type
          }
          beer_name
          beer_type
          image
        }
      }
    }
  }
}
`;

export default ({ data, prismic }) => {

  console.log(data.prismic.beers.edges);

  // todo: græja thumb af iamge

  const beers = data.prismic.beers.edges;

  if (!beers) {
    return null;
  }
  return (
    <>
      <Helmet title="Home" />

      <BeerCollage>
        {beers.map((beer) => (
          <Beer
            key={beer.node._meta.uid}
            image={beer.node.image.thumb.url}
            title={RichText.asText(beer.node.beer_name)}
            type={RichText.asText(beer.node.beer_type)}
            to={createLink(beer.node)}
          />
        ))}
      </BeerCollage>

    </>
  );
};

export default function createLink(doc: any, localize: boolean = false) {

  if (doc === null || doc === undefined) { return '/'; }

  const { _meta = {}} = doc;
  const { uid = '', type = '' } = _meta;

  if (type) {
    switch (type) {
      case 'PRISMIC_Page_content':
        return `/page/${uid}`;
      case 'beer':
        return `/beers/${uid}`;

      default:
        return `/${uid}`;
    }
  }
  return '/';
}

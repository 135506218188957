let Masonry;
let imagesLoaded;

 if (typeof window !== 'undefined') {
  Masonry = require('masonry-layout');
  imagesLoaded = require('imagesloaded');
}

module.exports = {
  imagesLoaded,
  Masonry,
};

import { get, isEmpty } from 'lodash';
import { Link, RichText as PrismicRichText } from 'prismic-reactjs';
import React, { PureComponent } from 'react';
import htmlSerializer from 'utils/htmlSerializer';
import linkResolver from 'utils/linkResolver';

import s from './RichText.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

export default class RichText extends PureComponent<IProps> {

  static defaultProps = {
    children: (<div />),
  };

  static asText = (object: any, path?: string) => {
    if (isEmpty(object) || object === null) { return null; }

    return PrismicRichText.asText(path ? get(object, path) : object);
  }

  static render = (object: any, path?: string) => {
    if (isEmpty(object) || object === null) { return null; }

    return PrismicRichText.render(path ? get(object, path, []) : object, linkResolver, htmlSerializer);
  }
  static url = (object: any, path?: string) => {
    if (object === null) {
      return null;
    }

    return Link.url(path ? get(object, path, []) : object, linkResolver);
  }

  render() {
    const { children } = this.props;

    return (
      <div className={s('richText', this.props.className)}>
        {children}
      </div>
    );
  }
}

import * as React from 'react';

import { TweenMax } from 'gsap';
import { Image } from 'components/image/Image';
import { Link as InternalLink } from 'components/link/Link';

import s from './Beer.scss';

interface IBeerProps {
  name: string;
  title: string;
  type: string;
  image: string;
  to: string;
}

export default class Beer extends React.Component<IBeerCollageProps> {
  refTitle = React.createRef<HTMLHeadingElement>();
  refType = React.createRef<HTMLHeadingElement>();
  refImage = React.createRef<HTMLDivElement>();
  refBeer = React.createRef<HTMLDivElement>();

  // tilt values
  tiltConfig = {
    type: {translation : {x: [-8, 8], y: [4, -4]}},
    title: {translation : {x: [-5, 5], y: [-10, 10]}},
    image: {translation : {x: [-15, 15], y: [-10, 10]}},
  };

  componentDidMount() {
    // test
    this.refBeer.current.addEventListener('mouseenter', this.mouseenterFn);
    this.refBeer.current.addEventListener('mousemove', this.mousemoveFn);
    this.refBeer.current.addEventListener('mouseleave', this.mouseleaveFn);
  }

  componentWillUnmount() {
    // this.refBeer.current.removeEventListener('mouseenter', this.mouseenterFn);
    // this.refBeer.current.removeEventListener('mousemove', this.mousemoveFn);
    // this.refBeer.current.removeEventListener('mouseleave', this.mouseleaveFn);
  }

  toggleAnimationOnHover = (type) => {

    TweenMax.to(this.refTitle.current, 1, {
      ease: Expo.easeOut,
      opacity: type === 'mouseenter' ? 1 : 0,
    });

    TweenMax.to(this.refType.current, 1, {
      ease: Expo.easeOut,
      opacity: type === 'mouseenter' ? 1 : 0,
    });
  }

  mouseenterFn = (ev) => {
    this.toggleAnimationOnHover(ev.type);
  }

  mousemoveFn = (ev) => requestAnimationFrame(() => {
    this.tilt(ev);
  })

  mouseleaveFn = (ev) => {
    this.resetTilt();
    this.toggleAnimationOnHover(ev.type);
  }

  // Gets the mouse position.
  getMousePos = (e) => {
    let posx = 0;
    let posy = 0;

    if (!e) {
      e = window.event;
    }

    if (e.pageX || e.pageY) 	{
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) 	{
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    return { x : posx, y : posy };
  }

  tilt = (ev) => {
    // Get mouse position.
    const mousepos = this.getMousePos(ev);
    const body = document.body;
    const docEl = document.documentElement;

    // Document scrolls.
    const docScrolls = {left : body.scrollLeft + docEl.scrollLeft, top : body.scrollTop + docEl.scrollTop};
    const bounds = this.refBeer.current.getBoundingClientRect();

    // Mouse position relative to the main element (this.DOM.el).
    const relmousepos = {
      x : mousepos.x - bounds.left - docScrolls.left,
      y : mousepos.y - bounds.top - docScrolls.top,
    };

    // Animate each of the elements..
    TweenMax.to(this.refType.current, 2, {
      ease: Expo.easeOut,
      x: (8 - -8) / bounds.width * relmousepos.x + -8,
      y: (-4 - 4) / bounds.height * relmousepos.y + 4,
    });

    TweenMax.to(this.refTitle.current, 2, {
      ease: Expo.easeOut,
      x: (5 - -5) / bounds.width * relmousepos.x + -5,
      y: (10 - -10) / bounds.height * relmousepos.y + -10,
    });

    TweenMax.to(this.refImage.current, 2, {
      ease: Expo.easeOut,
      x: (15 - -15) / bounds.width * relmousepos.x + -15,
      y: (10 - -10) / bounds.height * relmousepos.y + -10,
    });

  }

  resetTilt = () => {
    TweenMax.to([this.refImage.current, this.refTitle.current, this.refType.current], 2, {
      ease: Elastic.easeOut.config(1, 0.4),
      x: 0,
      y: 0,
    });
  }

  render() {
    const { title, type, to, image } = this.props;

    return (
      <div className={s.beer} ref={this.refBeer}>
        <InternalLink
          className={s.beer__link}
          to={to}
        >
          <div className={s.beer__bg} />
          <div className={s.beer__wrap} ref={this.refImage}>{/* class="grid__item-wrap"> */}
              <Image
                className={s.beer__image}
                src={image}
              />
          </div>
          <h3 className={s.beer__type} ref={this.refType}>{type}</h3> {/* grid__item-title */}
          <h4 className={s.beer__title} ref={this.refTitle}>{title}</h4> {/* grid__item-number */}
        </InternalLink>
      </div>
    );
  }
}

import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { Masonry, imagesLoaded } from 'utils/grid';

import { Link } from 'components/link/Link';
import { Image } from 'components/image/Image';

import s from './BeerCollage.scss';

interface IBeerCollageProps {
  children: React.ReactNode;
}

export default class BeerCollage extends React.Component<IBeerCollageProps> {

  gridNode = React.createRef<HTMLDivElement>();
  grid = null;
  // refIntro = React.createRef<HTMLDivElement>();
  // refContent = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.initGrid();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      // this.initGrid();
    }
  }

  componentWillUnmount() {
    if (this.grid) {
      this.grid.destroy();
    }
  }

  initGrid = () => {
    if (!this.gridNode.current) { return; }

    imagesLoaded(this.gridNode.current, () => {
      this.grid = new Masonry( this.gridNode.current,
        { columnWidth: 260, gutter: 100, fitWidth: true, isAnimated: false },
      );
    });

  }

  animate = () => {
    // const t = new TimelineLite();
    //
    // t.addLabel('start', 0);
    //
    // if (this.refIntro.current) {
    //   t.fromTo(
    //     this.refIntro.current,
    //     1.3,
    //     { autoAlpha: 0, y: '90px' },
    //     { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
    //     'start',
    //   );
    // }
    //
    // if (this.workImage.current) {
    //   t.fromTo(
    //     this.workImage.current,
    //     1.8,
    //     { autoAlpha: 0, y: '90px' },
    //     { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
    //     'start+=0.4',
    //   );
    // }
    //
    // if (this.refContent.current) {
    //   t.fromTo(
    //     this.refContent.current,
    //     1.3,
    //     { autoAlpha: 0, y: '50px' },
    //     { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
    //     'start+=0.6',
    //   );
    // }
  }

  render() {
    const { children } = this.props;

    return (
      <div className={s.beerCollage}>
        <div className={s.beerCollage__container}>
          <div className={s.beerCollage__grid} ref={this.gridNode}>
            {React.Children.map(children, (child, i) => (
                <div className={s.beerCollage__item} key={i}>
                  <div className={s.beerCollage__itemWrap}>
                    {child}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
